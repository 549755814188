import { isInQuickFiltersExperiment } from '@redux/experiments/selectors/quick-filters';
import { useAppSelector } from '@redux/hooks';
import { toSentenceCase } from '@utils/toSentenceCase';
import Icon from '@xo-union/tk-component-icons';
import React, { type ReactNode } from 'react';
import Styles from './styles.scss';

interface PillProps {
	label: string | ReactNode;
	onClick: () => void;
	customStyles?: string;
	selected: boolean;
	customSelectedStyles?: string;
	showMapView?: boolean;
}

const Pill = ({
	label,
	onClick,
	customStyles,
	selected,
	customSelectedStyles,
	showMapView,
}: PillProps) => {
	const pillClasses = `${
		showMapView ? Styles.pillMapContainer : Styles.pillContainer
	} ${customStyles} ${selected ? customSelectedStyles || Styles.selected : ''}`;

	const processedLabel =
		typeof label === 'string' ? toSentenceCase(label) : label;

	const isInQuickFilters = useAppSelector(isInQuickFiltersExperiment);

	return (
		<button type="button" className={pillClasses} onClick={onClick}>
			{isInQuickFilters &&
			(processedLabel === 'Distance' || processedLabel === 'Price') ? (
				<span className={Styles.labelWithIcon}>
					{processedLabel}
					<Icon name={'caret_down'} size="sm" />
				</span>
			) : (
				processedLabel
			)}
		</button>
	);
};

export default Pill;
