import { selectAssignment } from '@redux/experiments/selectors/index';
import type { QuickFiltersAssignment } from '@typings/experiments';
import type { State } from 'types/redux';

const quickFiltersSelector = (state: State) => {
	if (state.category.code !== 'REC' || state.membership.member === null) {
		return null;
	}

	return selectAssignment(state, ['quickFilters']) as QuickFiltersAssignment;
};

const isInQuickFiltersExperiment = (state: State) => {
	const quickFiltersAssignment = quickFiltersSelector(state);
	return quickFiltersAssignment === 'quick-filters';
};

export { quickFiltersSelector, isInQuickFiltersExperiment };
